import React from "react";
import { AppBar, Toolbar, styled, Typography } from "@mui/material";

const appName = "ZENDAI CAPTURE";

const StyledAppHeader = styled(AppBar)({
  padding: "0px",
});

const StyledToolbar = styled(Toolbar)({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#A60A3D",
});

const StyledTypography = styled(Typography)({
  alignContent: "center",
  fontSize: "1.5rem",
  textTransform: "uppercase",

  "@media (max-width: 350px )": {
    fontSize: "1.25rem",
  },
});

export const Navbar = () => {
  return (
    <StyledAppHeader>
      <StyledToolbar>
        <StyledTypography>{appName}</StyledTypography>
      </StyledToolbar>
    </StyledAppHeader>
  );
};
