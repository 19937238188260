import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { initializeAnalytics, reactPlugin } from "./analytics";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import "./index.css";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { Navbar } from "./components/Navbar";
import { fetchAuthToken } from "./utils/helpers";
import { NotFound } from "./notfound";
import { AccountInfo } from "@azure/msal-browser";
import { App as Capture } from "@ez/capture-library/lib/lib/App";

initializeAnalytics();

function App() {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [loggedInUser, setLoggedInUser] = useState<AccountInfo>();
  const [selectedType, setSelectedType] = useState<string>("");
  const [defaultData, setDefaultData] = useState<object | undefined>({});

  const { instance } = useMsal();

  let activeAccount = instance.getActiveAccount();

  const getAuthTokenAsync = async () => {
    return await fetchAuthToken(instance, loginRequest);
  };

  const isMentionFunctionalityEnabled =
    process.env.REACT_APP_ENABLE_MENTION_FUNCTIONALITY === "true";
  const IsAboutPageShown = process.env.REACT_APP_ENABLE_ABOUT_PAGE === "true";
  const getBaseUrl = () => process.env.REACT_APP_API_BASE_URL!;

  useEffect(() => {
    const handleRedirect = async () => {
      await instance.handleRedirectPromise();

      try {
        if (!activeAccount) {
          await instance.loginRedirect(loginRequest);
          setLoggedIn(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (!isLoggedIn) handleRedirect();

    if (activeAccount) {
      setLoggedInUser(activeAccount!);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const defauldDataBasedOnType = () => {
      if (selectedType === "Pitch deck") {
        return {
          defaultClient: {
            id: 1,
            firstName: "John",
            lastName: "Doe 1",
          },
          defaultCandidate: {
            id: 2,
            firstName: "David",
            lastName: "Doe 2",
          },
          defaultProject: {
            id: 2,
            name: "Best Project",
          },
        };
      }
      if (selectedType === "Search strategy") {
        return {
          defaultProject: {
            id: 2,
            name: "Best Project",
          },
        };
      } else {
        return {};
      }
    };

    const data = defauldDataBasedOnType();
    setDefaultData(data);
  }, [selectedType]);

  const handleSelection = (newType: string) => {
    if (selectedType === newType) {
      setSelectedType("");
      setTimeout(() => setSelectedType(newType), 0);
    } else {
      setSelectedType(newType);
    }
  };

  return (
    <>
      <AppInsightsContext.Provider value={reactPlugin}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={
                <Capture
                  getAuthTokenAsync={getAuthTokenAsync}
                  apiBaseUrl={getBaseUrl()}
                  loggedInUser={loggedInUser}
                  IsAboutPageShown={IsAboutPageShown}
                  isMentionFunctionalityEnabled={isMentionFunctionalityEnabled}
                  setSelectedType={handleSelection}
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AppInsightsContext.Provider>
    </>
  );
}

export default App;
